<template>
  <v-card class="border-right border-radius-bottom-right border-radius0" :height="pageHeight(270)">
    <div class="pa-3">
      <v-btn :disabled="detailedUser.userData.status === 99" :ripple="false" class="text-center" color="white" block @click="synchronizeDrawers('patientEdit')">
        <v-icon left small color="main_black">mdi-pencil</v-icon>
        <span class="color-black font-size13">{{ $t("edit-details") }}</span>
      </v-btn>
    </div>
    <div>
      <v-row>
        <v-col>
          <v-col class="mx-1">
            <span class="font-weight-bold color-black font-size18 float-left">{{ detailedUser.userData.firstName + ' ' + detailedUser.userData.lastName }}</span>
            <span class="text-uppercase float-right mr-2 mt-1 font-size14 " :style="{ fontWeight: detailedUser.userData.status === 1 ? 'bold' : 'bold', color: detailedUser.userData.status === 1 ? '#09CB09' : '' }">
              <v-icon small class="mr-1 font-size14"
                      :style="{
                        color: detailedUser.userData.status === 1 ? '#09CB09' : (detailedUser.userData.status === 99 ? 'black' : '')
                      }">
                mdi-checkbox-blank-circle
              </v-icon>
                {{ detailedUser.userData.status === 1 ? $t('active-caps') : detailedUser.userData.status === 0 ? $t('inactive-caps') : detailedUser.userData.status === 99 ? $t('deceased-caps') : '' }}
              </span>
          </v-col>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col class="text-left ml-4">
          <v-icon size="15" color="primary">mdi-email</v-icon>
          <span class="ml-2 color-primary font-size15"> {{ detailedUser.userData.email }}</span>
          <div v-if="detailedUser.userData.status !== 99" class="float-right">
                <v-btn class="mr-1 color-primary" x-small @click.stop="openDeceasedDialog">
                  <v-icon x-small>mdi-grave-stone</v-icon>
                  {{$t('make-deceased')}}
                </v-btn>
          </div>
          <br>
          <v-icon size="15" color="primary" >mdi-phone</v-icon>
          <span class="ml-2 color-primary font-size15" >{{phone(detailedUser.userData.phone)}}</span>
        </v-col>
        <v-overlay :value="loading">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </v-overlay>
      </v-row>
      <v-row dense>
        <v-col class="text-left ml-4 mt-n2">
          <div class="float-right">
            <v-tooltip color="primary" top>
              <template v-slot:activator="{on}">
                <v-icon class="mr-1 color-primary font-size" @click="openDialog" v-on="on">mdi mdi-eye</v-icon>
              </template>
              <span>{{$t('download-medical-notes')}}</span>
            </v-tooltip>

            <v-tooltip color="primary" top>
              <template v-slot:activator="{on}">
                <v-icon v-on="on" class="mr-5 color-primary" @click="openMedicalRecords" :disabled="!getSurveyFlag">mdi-cloud-download</v-icon>
              </template>
              <span>{{$t('download-last-month-measurements')}}</span>
            </v-tooltip>

          </div>
        </v-col>
      </v-row>
      <v-row class="pa-0 ma-0 mx-3 mt-2 mb-5">
        <v-col cols="4" class="data-table-outline border-radius-left5 d-flex flex-column-reverse pa-0">
          <span class="font-size14">{{ $t("age") }}</span>
          <span class="font-weight-bold font-size14">{{ calculateAge(detailedUser.userData.birthdate) }}</span>
        </v-col>
        <v-col cols="4" class="data-table-outline flex-column d-flex pa-0">
          <span class="font-weight-bold font-size14 mt-1">{{userSettings.Units === "METRIC" ?
              detailedUser.userData.patientMetadata.weightInKg : toLbs
              (detailedUser.userData.patientMetadata.weightInKg)}}
              {{userSettings.Units === "METRIC" ? 'kg' : 'lbs'}}
           </span>
          <span class="font-size14">{{ $t("weight") }}</span>
        </v-col>
        <v-col cols="4" class="data-table-outline border-radius-right5 d-flex flex-column-reverse pa-0">
          <span class="font-size14">{{$t('height')}}</span>
          <span class="font-weight-bold font-size14">{{ userSettings.Units === 'IMPERIAL' ?
              formatHeightInFeet(detailedUser.userData.patientMetadata.heightInCm)
              : detailedUser.userData.patientMetadata.heightInCm + ' cm' }}
          </span>
        </v-col>
      </v-row>
    </div>
    <v-card class="overflow-y-auto overflow-x-hidden elevation-0 " :height="pageHeight(34)">
      <v-card class="ma-3">
        <v-card-title class="pa-0 card-header-details">
          <v-col cols="6" class="pa-0">
            <span class="float-left font-size14 font-weight-bold ml-4">{{ $t("problem-list") }}</span>
          </v-col>
          <v-col cols="6">
            <v-icon :disabled="detailedUser.userData.status === 99" color="primary" size="18" @click="synchronizeDrawers('diagnosisEdit')" class="float-right">mdi-pencil</v-icon>
          </v-col>
        </v-card-title>
        <v-card-text v-html="getLatestDiagnosis" style="overflow-y: auto; max-height: 15vh;" class="pl-4 font-weight-bold"></v-card-text>
      </v-card>
      <v-card class="ma-3">
        <v-card-title class="pa-0 card-header-details">
          <v-col cols="7" class="pa-0">
            <span class="float-left font-size14 font-weight-bold ml-4">{{$t("treatment-summary")}}</span>
          </v-col>
          <v-col cols="5">
            <v-icon :disabled="detailedUser.userData.status === 99" @click="synchronizeDrawers('treatmentEdit')" class="float-right" color="primary" size="18">mdi-pencil</v-icon>
          </v-col>
        </v-card-title>
        <div>
          <v-card-text v-html="getTreatmentSummary" v-if="getTreatmentSummary" style="overflow-y: auto; max-height: 15vh;" class="pl-4 py-0 my-0"></v-card-text>
        </div>
      </v-card>
      <v-card class="ma-3">
        <v-card-title class="pa-0 card-header-details">
          <v-col cols="6" class="pa-0">
            <span class="float-left font-size14 font-weight-bold ml-4">{{ $t("allergies") }}</span>
          </v-col>
          <v-col cols="6">
            <v-icon :disabled="detailedUser.userData.status === 99" @click="synchronizeDrawers('allergiesEdit')" class="float-right" color="primary" size="18">mdi-pencil</v-icon>
          </v-col>
        </v-card-title>
        <div>
            <v-card-text v-html="getAllergies"  style="overflow-y: auto; max-height: 15vh;" class="py-0 my-0" ></v-card-text>
        </div>
      </v-card>
      <v-card class="ma-3">
        <v-card-title class="pa-0 card-header-details">
          <v-col cols="6" class="pa-0">
            <span  class="float-left font-size14 ml-4">{{ $t("medicalTeam") }}</span>
          </v-col>
          <v-col cols="6" class="pa-0">
            <h6 @click="triggerMedicalTeam" class="float-right mr-2 color-primary pointer font-size14">{{$t('view-all')}}</h6>
          </v-col>
        </v-card-title>
        <v-card-text class="text-left" style="overflow-y: auto; max-height: 9vh;">
          <span class="my-2 font-size14 font-weight-bold">{{$t('primary-doctor')}}: {{ detailedUser.userData.medicalTeamsData[0].primaryDoctorFullName }}</span>
          <v-col v-if="showMedicalTeam" class="pa-0">
          <span
              v-for="member in detailedUser.userData.medicalTeamsData[0].membersList"
              :key="member.id"
              class="d-block"
          >
            <span>{{member.firstName}} {{member.lastName}}</span>
          </span>
          </v-col>
        </v-card-text>
      </v-card>
    </v-card>
    <show-notes-dialog :dialog.sync="showDialog" :detailed-user="detailedUser" v-if="showDialog"></show-notes-dialog>
    <medical-records
        :assigned-medical-devices="assignedMedicalDevices"
        :dialog.sync="medicalRecords"
        :detailed-user="detailedUser"
        v-if="medicalRecords"
    ></medical-records>
    <DeceasedDialog v-if="dialogDelete" @close-dialog="dialogDelete = false" :dialog.sync="dialogDelete" :detailed-user="detailedUser"></DeceasedDialog>
  </v-card>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import { fromTo } from '@/utils/unit-converter.js';
import { AsYouType, isValidPhoneNumber } from 'libphonenumber-js';
import { doctorBlack, patientBlack } from '@/assets';
import showNotesDialog from '@/views/dialogs/ShowNotesDialog';
import MedicalRecords from '@/views/dialogs/MedicalRecords';
import DeceasedDialog from '@/views/dialogs/DeceasedDialog.vue';
export default {
  components: {
    showNotesDialog,
    MedicalRecords,
    DeceasedDialog,
  },
  data () {
    return {
      allergies: null,
      dialogDelete: false,
      patientBlack: patientBlack,
      doctorBlack: doctorBlack,
      treatments: null,
      buttonDisalbed: true,
      showMedicalTeam: false,
      getResponse: null,
      iconDisabled: true,
      loading: false,
      showDialog: false,
      filterChange: false,
      filterChat: 'LAST7',
      enableDownload: false,
      medicalRecords: false,
    };
  },
  computed: {
    ...mapState({
      detailedUser: (state) => state.users.detailedUser,
      hubs: (state) => state.nosessionhubs.hubs,
      surveyForPatient: (state) => state.mentalhealth.surveyPatentDetails,
    }),
    ...mapGetters({
      userSettings: 'authentication/getUserSettings',
      pageHeight: 'pageHeight',
      hospitalId: 'authentication/getHospitalId',
    }),
    getSurveyFlag: {
      get () {
        return this.$store.state.mentalhealth.getSurveyFlag;
      },
      // Define a setter even if it's not used
      set (value) {
        // You can leave it empty or log a message if needed
      },
    },
    getLatestDiagnosis () {
      const diagnoses = this.detailedUser.userData.patientDiagnosisList.map(diagnosis => {
        return `<p class="mb-1"><b>${diagnosis.diagnosisCode}</b> ${diagnosis.diagnoseDescription}</p>`;
      });
      return `<div class="text-left px-0">${diagnoses.join('')}</div>`;
    },
    assignedMedicalDevices () {
      return this.hubs.assignedMedicalDevicesList?.map((item) => ({
        deviceDefaultValuesJson: item.deviceDefaultValuesJson,
        deviceType: item.deviceType,
        measurementsData: item.measurementsData,
        deviceName: item.deviceName,
        deviceNameEsp: item.deviceNameEsp,
      }));
    },
    getTreatmentSummary () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.treatments = this.detailedUser.userData.patientMetadata.prescriptions;
      const treatments = this.detailedUser.userData.patientMetadata.prescriptions;
      if (treatments === null) {
        return null;
      }
      const formattedTreatments = `<p class="mb-1">${treatments}</p>`;
      return `<div class="text-left px-0">${formattedTreatments}</div>`;
    },
    getAllergies () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.allergies = this.detailedUser.userData.patientMetadata.metadataInfo;
      const metadataInfo = this.detailedUser.userData.patientMetadata.metadataInfo;
      if (metadataInfo === null) {
        return null;
      }
      const formattedMetadataInfo = `<p class="mb-1">${metadataInfo}</p>`;
      return `<div class="text-left">${formattedMetadataInfo}</div>`;
    },
  },
  async mounted () {
    if (this.detailedUser === null) {
      await this.getUserById();
    }
    this.$on('enable-icon', this.iconDisabled);
    if (this.surveyForPatient) {
      this.buttonDisalbed = true;
    }
  },
  watch: {
    filterChat () {
      this.filterChange = true;
    },
    filterChanged (val) {
      this.filterCase = val;
      this.getMyNotifications(this.filterCase);
    },
  },

  methods: {
    async openDeceasedDialog () {
      this.dialogDelete = true;
    },
    beforeDestroy () {
      this.$off('enable-icon', this.iconDisabled);
      localStorage.removeItem('resFlag');
    },
    openMedicalRecords () {
      this.medicalRecords = !this.medicalRecords;
    },
    openDialog () {
        this.loading = true;
        const body = {
          patientId: this.$route.params.id,
          hospitalId: this.hospitalId,
        };
        this.$store.dispatch('appointments/getmedicalnoteforpatientbyid', body)
            .then(() => {
              // API call succeeded
              this.showDialog = !this.showDialog;
            })
            .finally(() => {
              // Hide loading indicator whether the API call succeeds or fails
              this.loading = false;
            });
    },
    triggerMedicalTeam () {
      this.showMedicalTeam = !this.showMedicalTeam;
    },
    formatHeightInFeet () {
      if (this.userSettings.Units === 'IMPERIAL') {
        const totalInches = Math.round(this.detailedUser.userData.patientMetadata.heightInCm / 2.54);
        const feet = Math.floor(totalInches / 12);
        const remainingInches = totalInches % 12;
        const tenthsOfFoot = remainingInches / 12;
        const heightInFeet = feet + tenthsOfFoot;
        return heightInFeet.toFixed(1) + ' ft';
      } else {
        return this.detailedUser.userData.patientMetadata.heightInCm + ' cm';
      }
    },
    synchronizeDrawers (drawer) {
      this.$store.commit('SET_PATIENT_DETAILS_DRAWER', drawer);
      this.$store.commit('SET_UPDATE_DETAILS_DRAWER', {
        updateDrawer: true,
        page2: 'patientdetails',
      });
    },
    phone: function (val) {
      if (val !== undefined) {
        this.phoneErrors = !isValidPhoneNumber(val);
        return new AsYouType().input(val);
      }
    },
    async getUserById () {
      this.userLoading = true;
      await this.$store
          .dispatch('users/getUserById', this.$route.params.id)
          .then(() => {
            this.$store.dispatch('patientdiagnosis/getDiagnosisListForPatient', this.$route.params.id);
            this.userLoading = false;
          });
    },
    toFeet (value) {
      return fromTo(value, 'cm', 'ft-us').toFixed(2);
    },
    toLbs (value) {
      return fromTo(value, 'kg', 'lb').toFixed(0);
    },
    calculateAge (dateString) {
      const today = new Date();
      const birthDate = new Date(dateString?.split('T')[0]);
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
  },
};
</script>
